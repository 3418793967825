import * as zod from 'zod';

import { CustomEquipmentActionConfiguration } from '@sb/integrations/types/customEquipmentTypes';

export const CNCMachineCommand = zod.object({
  kind: zod.literal('CNCMachineCommand'),
  deviceID: zod.string(),
  subCommand: zod.discriminatedUnion('kind', [
    zod.object({
      kind: zod.literal('runProgram'),
      haasProgram: zod.string(),
    }),
    zod.object({
      kind: zod.literal('autodoor'),
      actionID: zod.string(),
    }),
    zod.object({
      kind: zod.literal('workholding'),
      workholdingID: zod.number(),
      actionID: zod.string(),
    }),
    zod.object({
      kind: zod.literal('haasCellSafe'),
      isEnabled: zod.boolean(),
    }),
    zod.object({
      kind: zod.literal('executeAction'),
      action: CustomEquipmentActionConfiguration,
    }),
  ]),
});

export type CNCMachineCommand = zod.infer<typeof CNCMachineCommand>;
