export const reset = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M22.75 14C22.75 9.16748 18.8325 5.25 14 5.25C9.50559 5.25 5.80262 8.63855 5.30649 13.0002L7.24998 13L7.66598 13.7774L4.66533 18.2774L3.83328 18.2773L0.833931 13.7773L1.24998 13L3.29589 13.0001C3.80028 7.5318 8.4001 3.25 14 3.25C19.9371 3.25 24.75 8.06293 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C10.833 24.75 7.98582 23.3805 6.01846 21.2014L7.17166 19.4719C8.77529 21.4705 11.2382 22.75 14 22.75C18.8325 22.75 22.75 18.8325 22.75 14ZM14 16.25C15.2426 16.25 16.25 15.2426 16.25 14C16.25 12.7574 15.2426 11.75 14 11.75C12.7574 11.75 11.75 12.7574 11.75 14C11.75 15.2426 12.7574 16.25 14 16.25Z"
    fill="currentColor"
  />
);

export const resetNoDot = (
  <path
    d="M22.75 14C22.75 9.16748 18.8325 5.25 14 5.25C9.50559 5.25 5.80262 8.63855 5.30649 13.0002L7.24998 13L7.66598 13.7774L4.66533 18.2774L3.83328 18.2773L0.833931 13.7773L1.24998 13L3.29589 13.0001C3.80028 7.5318 8.4001 3.25 14 3.25C19.9371 3.25 24.75 8.06293 24.75 14C24.75 19.9371 19.9371 24.75 14 24.75C10.833 24.75 7.98582 23.3805 6.01846 21.2014L7.17166 19.4719C8.77529 21.4705 11.2382 22.75 14 22.75C18.8325 22.75 22.75 18.8325 22.75 14Z"
    fill="currentColor"
  />
);
