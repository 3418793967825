import * as zod from 'zod';

import { Expression } from '@sb/routine-runner';

export const EnvironmentVariable = zod.object({
  id: zod.string(),
  name: zod.string(),
  initialValue: Expression.optional(),
  isGlobal: zod.boolean().optional(),
});

export interface EnvironmentVariable
  extends zod.infer<typeof EnvironmentVariable> {}
