import { makeNamespacedLog } from '@sb/log';
import type { EquipmentStateItem } from '@sb/routine-runner';
import { FailureKind } from '@sb/routine-runner';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';
import { wait } from '@sb/utilities';

import { WeldArguments } from './Arguments';
import { WeldVariables } from './Variables';

const log = makeNamespacedLog('WeldStep');

export class WeldStep extends Step<WeldArguments, WeldVariables> {
  public static areSubstepsRequired = true;

  public static Arguments = WeldArguments;

  public static Variables = WeldVariables;

  public substeps: Array<Step<object, object>> = [];

  protected initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail, playSubSteps }: StepPlayArguments): Promise<void> {
    log.info('_play', 'Playing Weld step');

    const { weldMachine, torch } = this.getActiveEquipment();

    if (weldMachine == null) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'Weld machine not found',
      });

      return;
    }

    if (torch == null) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'Weld torch not found',
      });

      return;
    }

    switch (weldMachine.kind) {
      case 'MillerWeldMachine':
        await this.playMillerWeldMachine({ playSubSteps });
        break;
      case 'EsabWeldMachine':
        fail({
          failure: {
            kind: FailureKind.WeldFailure,
          },
          failureReason: 'ESAB weld machine not supported',
        });

        break;
      case 'WeldMachine':
        fail({
          failure: {
            kind: FailureKind.WeldFailure,
          },
          failureReason: 'Generic weld machine not supported',
        });

        break;
      default:
        fail({
          failure: {
            kind: FailureKind.WeldFailure,
          },
          failureReason: `Unknown weld machine type: ${weldMachine.kind}`,
        });

        break;
    }
  }

  public async _stop(): Promise<void> {
    log.info('_stop', 'Stopping Weld step');

    const { weldMachine } = this.getActiveEquipment();

    if (weldMachine == null) {
      log.info('_stop', 'No weld machine found. Nothing to stop.');

      return;
    }

    switch (weldMachine.kind) {
      case 'MillerWeldMachine':
        await this.stopMillerWeldMachine();

        break;
      case 'EsabWeldMachine':
        log.error('_stop', 'ESAB weld machine not supported');

        break;
      case 'WeldMachine':
        log.error('_stop', 'Generic weld machine not supported');

        break;
      default:
        log.error('_stop', `Unknown weld machine type: ${weldMachine.kind}`);

        break;
    }
  }

  private getActiveEquipment(): {
    weldMachine: EquipmentStateItem | undefined;
    torch: EquipmentStateItem | undefined;
  } {
    const allEquipment = this.routineContext.equipment.getEquipmentState();

    const weldMachine = allEquipment.find(
      (equipment) => equipment.id === this.args.selectedMachineID,
    );

    const torch = allEquipment.find(
      (equipment) => equipment.id === this.args.selectedTorchID,
    );

    return { weldMachine, torch };
  }

  private async playMillerWeldMachine({
    playSubSteps,
  }: Pick<StepPlayArguments, 'playSubSteps'>): Promise<void> {
    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'setWeldParameters',
        weldTravelSpeed: 0, // This is disregarded by machine. Just set it like this for now.
        ...this.args.millerWeldParameters,
      },
    });

    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'startArc',
      },
    });

    wait(this.args.arcStartTime);

    await playSubSteps();

    wait(this.args.craterFillTime);

    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'stopArc',
      },
    });
  }

  private async stopMillerWeldMachine(): Promise<void> {
    await this.routineContext.equipment.executeCommand({
      kind: 'MillerWeldMachineCommand',
      deviceID: this.args.selectedMachineID,
      subCommand: {
        kind: 'stopArc',
      },
    });
  }
}
