import * as zod from 'zod';

import { CNCMonitorMachineStepDatabase } from '@sb/integrations/implementations/CNCMachine/step-wizard/CNCMonitorMachine/CNCMonitorMachineStepDatabase';
import { CNCOperateAutodoorStepDatabase } from '@sb/integrations/implementations/CNCMachine/step-wizard/CNCOperateAutodoor/CNCOperateAutodoorStepDatabase';
import { CNCOperateWorkholdingStepDatabase } from '@sb/integrations/implementations/CNCMachine/step-wizard/CNCOperateWorkholding/CNCOperateWorkholdingStepDatabase';
import { CNCRunProgramStepDatabase } from '@sb/integrations/implementations/CNCMachine/step-wizard/CNCRunProgram/CNCRunProgramStepDatabase';
import { MoveVerticalLiftStepDatabase } from '@sb/integrations/implementations/dynamic-base/step-wizard/MoveVerticalLiftStepDatabase';
import { ActuateGripperStepDatabase } from '@sb/integrations/implementations/gripper-general/step-wizard/ActuateGripperStepDatabase';
import { HaasControlRegionStepDatabase } from '@sb/integrations/implementations/HaasMill/frontend/HaasControlRegionStepDatabase';
import { HaasRunProgramStepDatabase } from '@sb/integrations/implementations/HaasMill/frontend/HaasRunProgramStepDatabase';
import { ActuateScrewdriverStepDatabase } from '@sb/integrations/implementations/onRobot/OnRobotScrewdriver/step-wizard/ActuateScrewdriverStepDatabase';
import { ActuateVacuumStepDatabase } from '@sb/integrations/implementations/onRobot/OnRobotVGP20/step-wizard/ActuateVacuumStepDatabase';
import { WeldStepDatabase } from '@sb/integrations/implementations/WeldMachine/shared/step-wizard/Weld/WeldStepDatabase';

import { AddOffsetStep } from './addOffset';
import { AnticipatePayloadStep } from './anticipatePayload';
import { ClassifyStep } from './classify';
import { CodeBlockStep } from './codeBlock';
import { IfStep } from './if';
import { LocateStep } from './locate';
import { LoopStep } from './loop';
import { LoopControlStep } from './loopControl';
import { MoveArmToTargetStep } from './moveArmTo';
import { NetworkRequestStep } from './networkRequest';
import { OperateEquipmentStep } from './operateEquipment';
import { PressButtonStep } from './pressButton';
import { PushModeStep } from './pushMode';
import { RunInBackgroundStep } from './runInBackground';
import { RunSkillStep } from './runSkill';
import { SetEnvironmentVariableStep } from './setEnvironmentVariable';
import { SetIOStep } from './setIO';
import { TriggerFaultStep } from './triggerFault';
import { WaitStep } from './wait';
import { WaitForConfirmationStep } from './waitForConfirmation';

/**
 * Makes sure each 'stepKind' has the right configuration.
 * For example, "If" steps receive different arguments than
 * "Loop" steps.
 */
export const StepConfigurationByKind = zod.discriminatedUnion('stepKind', [
  zod.object({
    args: ActuateScrewdriverStepDatabase.Arguments.optional(),
    stepKind: zod.literal('ActuateScrewdriver'),
  }),
  zod.object({
    args: ActuateGripperStepDatabase.Arguments.optional(),
    stepKind: zod.literal('ActuateGripper'),
  }),
  zod.object({
    args: ActuateVacuumStepDatabase.Arguments.optional(),
    stepKind: zod.literal('ActuateVacuum'),
  }),
  zod.object({
    args: AnticipatePayloadStep.Arguments.optional(),
    stepKind: zod.literal('AnticipatePayload'),
  }),
  zod.object({
    args: AddOffsetStep.Arguments.optional(),
    stepKind: zod.literal('AddOffset'),
  }),
  zod.object({
    args: ClassifyStep.Arguments.optional(),
    stepKind: zod.literal('Classify'),
  }),
  zod.object({
    args: CodeBlockStep.Arguments.optional(),
    stepKind: zod.literal('CodeBlock'),
  }),
  zod.object({
    args: LocateStep.Arguments.optional(),
    stepKind: zod.literal('Locate'),
  }),
  zod.object({
    args: HaasControlRegionStepDatabase.Arguments.optional(),
    stepKind: zod.literal('HaasControlRegion'),
  }),
  zod.object({
    args: HaasRunProgramStepDatabase.Arguments.optional(),
    stepKind: zod.literal('HaasRunProgram'),
  }),
  zod.object({
    args: IfStep.Arguments.optional(),
    stepKind: zod.literal('If'),
  }),
  zod.object({
    args: LoopStep.Arguments.optional(),
    stepKind: zod.literal('Loop'),
  }),
  zod.object({
    args: LoopControlStep.Arguments.optional(),
    stepKind: zod.literal('LoopControl'),
  }),
  zod.object({
    args: MoveArmToTargetStep.Arguments.optional(),
    stepKind: zod.literal('MoveArmTo'),
  }),
  zod.object({
    args: NetworkRequestStep.Arguments.optional(),
    stepKind: zod.literal('NetworkRequest'),
  }),
  zod.object({
    args: OperateEquipmentStep.Arguments.optional(),
    stepKind: zod.literal('OperateEquipment'),
  }),
  zod.object({
    args: PressButtonStep.Arguments.optional(),
    stepKind: zod.literal('PressButton'),
  }),
  zod.object({
    args: PushModeStep.Arguments.optional(),
    stepKind: zod.literal('PushMode'),
  }),
  zod.object({
    args: RunInBackgroundStep.Arguments.optional(),
    stepKind: zod.literal('RunInBackground'),
  }),
  zod.object({
    args: RunSkillStep.Arguments.optional(),
    stepKind: zod.literal('RunSkill'),
  }),
  zod.object({
    args: SetEnvironmentVariableStep.Arguments.optional(),
    stepKind: zod.literal('SetEnvironmentVariable'),
  }),
  zod.object({
    args: SetIOStep.Arguments.optional(),
    stepKind: zod.literal('SetIO'),
  }),
  zod.object({
    args: WaitStep.Arguments.optional(),
    stepKind: zod.literal('Wait'),
  }),
  zod.object({
    args: WaitForConfirmationStep.Arguments.optional(),
    stepKind: zod.literal('WaitForConfirmation'),
  }),
  zod.object({
    args: CNCRunProgramStepDatabase.Arguments.optional(),
    stepKind: zod.literal('CNCRunProgram'),
  }),
  zod.object({
    args: CNCMonitorMachineStepDatabase.Arguments.optional(),
    stepKind: zod.literal('CNCMonitorMachine'),
  }),
  zod.object({
    args: CNCOperateAutodoorStepDatabase.Arguments.optional(),
    stepKind: zod.literal('CNCOperateAutodoor'),
  }),
  zod.object({
    args: CNCOperateWorkholdingStepDatabase.Arguments.optional(),
    stepKind: zod.literal('CNCOperateWorkholding'),
  }),
  zod.object({
    args: MoveVerticalLiftStepDatabase.Arguments.optional(),
    stepKind: zod.literal('MoveVerticalLift'),
  }),
  zod.object({
    args: WeldStepDatabase.Arguments.optional(),
    stepKind: zod.literal('Weld'),
  }),
  zod.object({
    args: TriggerFaultStep.Arguments.optional(),
    stepKind: zod.literal('TriggerFault'),
  }),
]);

export type StepConfigurationByKind = zod.infer<typeof StepConfigurationByKind>;

// Cannot do pick on discriminated union.
// Trying to extract args from .map on union type leads to type confusion elsewhere. Just lump it for now.
export const StepConfigurationByKindArgs = zod.union([
  ActuateScrewdriverStepDatabase.Arguments,
  ActuateGripperStepDatabase.Arguments,
  ActuateVacuumStepDatabase.Arguments,
  AnticipatePayloadStep.Arguments,
  AddOffsetStep.Arguments,
  ClassifyStep.Arguments,
  CodeBlockStep.Arguments,
  LocateStep.Arguments,
  HaasControlRegionStepDatabase.Arguments,
  HaasRunProgramStepDatabase.Arguments,
  IfStep.Arguments,
  LoopStep.Arguments,
  LoopControlStep.Arguments,
  MoveArmToTargetStep.Arguments,
  NetworkRequestStep.Arguments,
  OperateEquipmentStep.Arguments,
  PressButtonStep.Arguments,
  PushModeStep.Arguments,
  RunInBackgroundStep.Arguments,
  RunSkillStep.Arguments,
  SetEnvironmentVariableStep.Arguments,
  SetIOStep.Arguments,
  WaitStep.Arguments,
  WaitForConfirmationStep.Arguments,
  CNCRunProgramStepDatabase.Arguments,
  CNCMonitorMachineStepDatabase.Arguments,
  CNCOperateAutodoorStepDatabase.Arguments,
  CNCOperateWorkholdingStepDatabase.Arguments,
  MoveVerticalLiftStepDatabase.Arguments,
  WeldStepDatabase.Arguments,
  TriggerFaultStep.Arguments,
]);

export type StepConfigurationByKindArgs = zod.infer<
  typeof StepConfigurationByKindArgs
>;
