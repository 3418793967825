import type * as zod from 'zod';

import type { Step } from '../step';
import type { User } from '../user';

import type { EditableFields as TEditableFields } from './editableFields';

export namespace Routine {
  /** Only these fields are editable by users. */
  export interface EditableFields extends zod.infer<typeof TEditableFields> {}

  /** Fields converted to the format used in the frontend. */
  export interface ConvertedResponse
    extends Omit<EditableFields, 'steps' | 'stepConfigurations'> {
    configurationUpdatedAt: Date;
    createdAt: Date;
    createdBy: User.Summary;
    id: string;
    isDisabled: boolean;
    isNew: boolean;
    steps: Step.ConvertedSummary[];
    stepConfigurations: Record<string, Step.ConvertedConfiguration>;
    updatedAt: Date;
  }

  export type Summary = Pick<
    ConvertedResponse,
    | 'id'
    | 'isDisabled'
    | 'isNew'
    | 'isTemplate'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'name'
    | 'updatedAt'
    | 'motionPlanner'
  >;
  export const VALID_MOTION_PLANNERS: EditableFields['motionPlanner'][] = [
    'ROS1',
    'ROS2',
  ];
}
