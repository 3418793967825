import * as zod from 'zod';

export const CNCOperateAutodoorArguments = zod.object({
  deviceID: zod.string(),
  actionID: zod.string(),
});

export type CNCOperateAutodoorArguments = zod.infer<
  typeof CNCOperateAutodoorArguments
>;
