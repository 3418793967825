import * as zod from 'zod';

export const CNCOperateWorkholdingArguments = zod.object({
  deviceID: zod.string(),
  workholdingID: zod.number(),
  actionID: zod.string(),
});

export type CNCOperateWorkholdingArguments = zod.infer<
  typeof CNCOperateWorkholdingArguments
>;
