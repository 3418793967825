import * as zod from 'zod';

import { convertMetersPerSecond } from '../../shared/convertMetersPerSecond';

import { WeldMachineMode } from './MillerWeldMachineState';

export const MILLER_WFS_BOUNDS = {
  min: convertMetersPerSecond.fromInchesPerMinute(50),
  max: convertMetersPerSecond.fromInchesPerMinute(780),
};

export const MillerWeldParameters = zod.object({
  mode: WeldMachineMode,
  // Max is 38v when power source is 350A rated. See section 3.4.1.8
  voltage: zod.number().gte(10).lte(44),
  arcLength: zod.number().gte(-10).lte(10),
  arcControl: zod.number().gte(0).lte(50),
  wireFeedSpeed: zod
    .number()
    .gte(MILLER_WFS_BOUNDS.min)
    .lte(MILLER_WFS_BOUNDS.max),
});

export const MillerWeldMachineCommand = zod.object({
  kind: zod.literal('MillerWeldMachineCommand'),
  deviceID: zod.string().optional(),
  subCommand: zod.discriminatedUnion('kind', [
    zod.object({
      kind: zod.literal('startArc'),
    }),
    zod.object({
      kind: zod.literal('stopArc'),
    }),
    zod.object({
      kind: zod.literal('startGas'),
    }),
    zod.object({
      kind: zod.literal('stopGas'),
    }),
    zod.object({
      kind: zod.literal('startWireFeed'),
      wireFeedSpeed: zod
        .number()
        .gte(MILLER_WFS_BOUNDS.min)
        .lte(MILLER_WFS_BOUNDS.max)
        .optional(),
      // Only include forward direction because that is what our wire feeder supports and we are concerned that going backwards would lead to tangled wire. If we ever need to go backwards, we just update this schema and the code is already implmented to go backwards in the weld machine.
      direction: zod.enum(['forward']),
    }),
    zod.object({
      kind: zod.literal('stopWireFeed'),
    }),
    zod.object({
      kind: zod.literal('setDryRunMode'),
      isDryRunMode: zod.boolean(),
    }),
    MillerWeldParameters.extend({
      kind: zod.literal('setWeldParameters'),
      weldTravelSpeed: zod.number().gte(0), // Meters/sec.
    }),
  ]),
});

export type MillerWeldMachineCommand = zod.infer<
  typeof MillerWeldMachineCommand
>;
