import type { FeatureFlagData } from '@sb/feathers-types/featureFlags';
import { FeatureFlagDataZod } from '@sb/feathers-types/featureFlags';
import * as log from '@sb/log';

import { readJSONFileWithFallback } from './util';

const ns = log.namespace('featureFlags');

const path = '/etc/standardbots/configuration/feature_flags.json';
let cache: FeatureFlagData | undefined;

export const updateFeatureFlagsCache = async (): Promise<void> => {
  cache = await readJSONFileWithFallback<FeatureFlagData>(
    path,
    FeatureFlagDataZod.parse({}),
  );
};

export const loadFeatureFlags = async (): Promise<FeatureFlagData> => {
  if (!cache) {
    cache = await readJSONFileWithFallback<FeatureFlagData>(
      path,
      FeatureFlagDataZod.parse({}),
    );
  }

  return cache;
};

export const getFeatureFlag = async <T extends keyof FeatureFlagData>(
  flag: T,
): Promise<FeatureFlagData[T]> => {
  const flags = await loadFeatureFlags();

  return flags[flag];
};

/**
 * Synchronous version of getFeatureFlag.  Feature flags must be loaded before
 * @param flag
 * @returns
 */
export const getFeatureFlagSync = <T extends keyof FeatureFlagData>(
  flag: T,
  defaultValue: FeatureFlagData[T],
): FeatureFlagData[T] => {
  if (!cache) {
    log.warn(ns`getFeatureFlagSync`, 'Feature flags not loaded yet');

    // this default value is only relevant if there is no cache, otherwise
    // the cache itself knows the true default value
    return defaultValue;
  }

  return cache[flag];
};
