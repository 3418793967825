import type {
  IntegrationActionGroup,
  IntegrationActionKind,
} from '@sb/integrations/io/types';
import {
  isIntegrationActionConfigured,
  isIntegrationActionMatch,
} from '@sb/integrations/io/utils';
import type { EquipmentItem } from '@sb/integrations/types';
import type { CustomEquipmentActionConfiguration } from '@sb/integrations/types/customEquipmentTypes';
import { ActionRequiredError } from '@sb/remote-control/types';

import type { CNCMachineConfiguration } from '../types';

export function getDevice(
  deviceID: string,
  equipment: EquipmentItem[],
): CNCMachineConfiguration {
  if (!deviceID) {
    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: 'Machine not selected',
      fieldId: 'deviceID',
    });
  }

  const device = equipment.find((d) => d.id === deviceID);

  if (device?.config.kind !== 'CNCMachine') {
    throw new ActionRequiredError({
      kind: 'missingEquipment',
      message: 'Machine not available',
      fieldId: 'deviceID',
    });
  }

  return device.config;
}

export function getWorkholding(
  config: CNCMachineConfiguration,
  workholdingID: number,
) {
  const workholding = config.workholdings[workholdingID];

  if (!workholding) {
    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: 'Workholding not selected',
      fieldId: 'workholdingID',
    });
  }

  return workholding;
}

function validateAction(
  action: CustomEquipmentActionConfiguration | undefined,
) {
  if (!action) {
    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: 'Action not available',
      fieldId: 'actionKind',
    });
  }

  if (!isIntegrationActionConfigured(action)) {
    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: 'Action not valid',
      fieldId: 'actionKind',
    });
  }
}

export function getAction(
  actionGroup: IntegrationActionGroup,
  actionKind: IntegrationActionKind,
  actionName?: string,
) {
  const action = actionGroup.actions.find((a) =>
    isIntegrationActionMatch(a, actionKind, actionName),
  );

  validateAction(action);

  return action;
}

export function getActionByID(
  actionGroup: IntegrationActionGroup,
  actionID: string,
) {
  const action = actionGroup.actions.find((a) => a.id === actionID);

  validateAction(action);

  return action;
}
